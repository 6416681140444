@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#uppercase {
  text-transform: capitalize;
}

.dict-meanings {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 55vh;
  border: 10px solid rgb(0, 102, 255);
  border-radius: 10px;
  padding: 10px 20px;
  overflow-x: hidden;
}

/* rgb(222, 5, 175)  - pink
rgb(119, 0, 255); - purple
*/

.dict-subTitle {
  font-size: 3vw;
  font-family: "Montserrat", sans-serif;
}

.singleMean {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0;
}

.dict-title {
  font-size: 130px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.dict-header{
  /* background-color: rgb(0, 102, 255); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 35vh;
  width: 100%;
}

.dict-inputs {
  width: 100%;
  display: flex;
  justify-content: space-around;
}


.dict-search {
  width: 50%;

}

@media (max-width: 900px) {
  .dict-meanings {
      height: 60vh;
      overflow-x: hidden;
      overflow-y: scroll;
    } 
  
  .dict-header {
      height: 25vh;
  }
  .dict-title {
      font-size: 11vw;
  }
  
  .dict-subTitle {
    font-size: 5vw;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

.game-bg {
    background-color: rgb(36, 35, 35);
    height: 100vh;
    margin: 0;
    font-family: 'Abril Fatface', cursive;
}

.game-container {
    display: flex;
    align-items: center;
    height: 800px;
    justify-content: space-evenly;
    flex-direction: column;
}

.game-score-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgb(237, 253, 248);
    border: solid maroon 10px;
    height: 220px;
    width: 100%;
}

.game-score-title {
    font-size: 100px;
    color: maroon;
}

#game-score, #game-result {
    font-size: 50px;
    color: maroon
}
#game-border {
      border: solid maroon 20px;
      border-radius: 10px;
}

.game-grid {
    display: flex;
    flex-wrap: wrap;
    width: 600px;
    height: 600px;
    background-color: black;
}

.game-grid div {
    width: 150px;
    height: 150px;
    font-style: normal;
    color: white;
    border: thick solid white;
    border-radius: 10px;
    background-color: pink;
    font-size: 75px;
    padding-top: 20px;
    text-align: center;
    /* justify-content: center; */
    font-weight: bold;
}

@media only screen and (max-width: 800px) {
    .game-container {
        height: 600px;
    }
    
    .game-score-container {
        height: 180px;
        width: 100%;
    }
    
    .game-score-title {
        font-size: 60px;
    }
    
    #game-score, #game-result {
        font-size: 30px;
    }
    #game-border {
          border: solid maroon 20px;
          border-radius: 10px;
    }
    
    .game-grid {
        width: 400px;
        height: 400px;
    }
    
    .game-grid div {
        width: 100px;
        height: 100px;
        border: thick solid white;
        /* border-radius: 10px; */
        font-size: 30px;
        padding-top: 20px;
    }
    
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .uppercase {
    text-transform: capitalize;
  }

  body {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
    
  .weather-app.cold {
    background-image: url('./Weather-Pics/Cold-Weather.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }
  
  .weather-app.storm{
    background-image: url('./Weather-Pics/Storm.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }
  
  .weather-app.cold-sunny {
    background-image: url('./Weather-Pics/Cold-Sunny-Weather.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }
  
  .weather-app.cloudy {
    background-image: url('./Weather-Pics/Cloudy.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }
  
  .weather-app.rain {
    background-image: url('./Weather-Pics/Rain.png');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }
  .weather-app.fog {
    background-image: url('./Weather-Pics/Fog.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }

  .weather-app.smoke {
    background-image: url('./Weather-Pics/Smoke.webp');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }

  .weather-app.sand {
    background-image: url('./Weather-Pics/Sand.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }

  .weather-app.tornado {
    background-image: url('./Weather-Pics/Tornado.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }

  .weather-app.sunny {
    background-image: url('./Weather-Pics/Warm-Weather.jpg');
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out
  }

  .weather-app {
    background-image: conic-gradient(red 25deg, 
     pink 130deg, pink, palevioletred,  darkred 290deg, darkred, red);
    background-size: cover;
    background-position: bottom;
    transition: 1s ease-out;
    /* color: palevioletred */
  }
  
  main {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    /* creates a gradient on the pictures */
    padding: 25px;
    overflow-y: hidden;
  }

  @media (max-width: 800px) {
    
   .search-box {
     display: flex;
     flex-wrap: wrap;
     padding: 20px;
     }

    select, input {
    width: 100%;
    margin-top: -25px;
    font-size: 20px;
    display: block
  }

  .submit-button {
    width: 50%;
    padding: 15px;
    margin-top: -25px;
    font-size: 20px;
    display: block;

  }
}
  
  .search-box {
    width: 100%;
    margin: 0 0 75px;
    display: flex;
    flex-direction: row;
    flex: 0.8;
    text-align: center;
    justify-content: center;
  }
  
  .search-box .search-bar {
    width: 25%;
    padding: 15px;
    display: block;
  
    appearance: none;
    background: none;
    border: none;
    outline: none;
  
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px 0px 16px 16px;
    margin-top: -25px;
  
    box-shadow: 0px 5 px rgba(0, 0, 0, 0.2);
  
    color: #313131;
    font-size: 20px;
  
    transition: 0.4s ease;
  
  }
  
  .submit-button {
    width: 25%;
    padding: 15px;
    display: block;
  
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px 0px 16px 16px;
    margin-top: -25px;
  
    box-shadow: 0px 5 px rgba(0, 0, 0, 0.2);
  
    color: #313131;
    font-size: 20px;
  
    transition: 0.4s ease;
  }
  
  .search-box .search-bar:focus {
    background-color: rgba(255, 255, 255, 0.75);
  }
  
  .location-box .location {
    color: #FFF;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
  }
  
  
  .location-box .date {
    color: #FFF;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    text-shadow: 2px 2px rgba(50, 50, 70, 0.5);
  }
  
  .weather-box {
    text-align: center;
  }
  
  .weather-box .temp {
    position: relative;
    display: inline-block;
    margin: 30px auto;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  
    padding: 15px, 25px;
  
    color: #FFF;
    font-size: 102px;
    font-weight: 900;
  
    text-shadow: 3px 6px rgba(50, 50, 70, 0.5);
    text-align: center;
    box-shadow: 3px 6px rgba(0, 0, 0, 0.2);
  }
  
  .weather-box .weather {
    color: #FFF;
    font-size: 48px;
    font-weight: 700;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
  }
  
  .weather-box .weather .weather-description{
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
  }
  
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

body {
    background-color: rgb(12, 54, 70);
    margin: 0;
    font-family: 'Josefin Sans', sans-serif;
    overflow-x: hidden;
} 

.logo {
    height: 162px;
    margin-top: -10px;
    border: solid rgb(237, 228, 185) 3px;
    position: relative;
    bottom: 50px;
    text-align: center;
}
/*rgb(245, 235, 178)*/

/*Testing multiple pictures moving through*/
/* @keyframes example {
    33% {
        background-image: url("../Home/Keyframes/white-prog.png");
    }
    66% {
        background-image: url("../Home/Keyframes/gray-dict.png");
    }
    100% {
        background-image: url("../Home/Keyframes/gray-prog.png");
    }
  }

.cont-dict {
    transition-property: opacity;
    transition-duration: s;
    transition-timing-function: linear;
    background-size: contain;
    background-image: url("../Home/Keyframes/white-dict.png");
}

.cont-dict:hover {
    animation-name: example;
    animation-duration: 8s;
} */

/*END*/

/* .cont-quiz {
    background-size: contain;
    background-image: url("../Home/Keyframes/quizmaster.png");
}

.cont-weather {
    background-size: contain;
    background-image: url("../Home/Keyframes/gray-dict.png");
}

.cont-game {
    background-size: contain;
    background-image: url("../Home/Keyframes/2048.png");
} */


.home-nav {
    display: flex;
    justify-content: center;
    height: 200px;
    background-image: url("../../../public/bigger-blue-gold.jpg");
    border-bottom: 3px solid rgb(237, 228, 185);
    background-position: center bottom;
    background-repeat: repeat-x;
    /* background-size: cover; */
    background-size: 1580px 660px;
    background-color: rgb(0, 75, 50);
    padding: 15px 0;
}

ul {
    display: flex;
}

li {
    color: white;
    /* text-shadow: 1px 1px 10px rgb(255, 255, 255), 0 0 25px rgb(255, 0, 0), 0 0 5px darkred; */
    font-size: 20px;
    position: relative;
    right: 8px;
    top: 45px;
    list-style-type: none;
    padding: 16px;
}

.row {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
}

.hero-section {
    position: absolute;
    top: 50%;
    left: 52%;
    width: 100%;
    height: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.container {
    border: solid rgb(237, 228, 185) 5px;
    width: 23vw;
    position: relative;
    transition: transform 500ms;
    display: block;
    margin: 10px;
    padding: 5px;
    color: yellow
}

.container img {
    width: 100%;
    opacity: 1;
    height: auto;
    display: block;
    z-index: 1;
}

.row .container:focus,
.row .container:hover {
    transform: scale(1.3);
    z-index: 1;
}

.middle {
    border-style: double;
    border-width: 5px;
    border-color: rgb(237, 228, 185);
    border-radius: 10px;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.container:hover .middle {
    opacity: 1
}

.text {
    text-align: center;
    color: white;
    font-size: 15px;
    padding: 8px 8px;
}

.footer {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    color: rgb(237, 228, 185);
}

a {
    text-decoration: none;
    color: black
}


@media only screen and (max-width: 1500px) {
    .text {
        font-size: 18px;
        padding: 8px 8px;
    }
/* Making this a new part???*/
    .hero-section {
        position: absolute;
        top: 500px;
        left: 49%;
        width: 80%;
        height: 50%;
        transform: translate(-50%, -50%);
    }
    
    .container {
        max-width: 50%;
        width: 40%;
        position: relative;
        margin: 5px;
    }
/*END*/

    .footer {
        position: relative;
    }

}

@media only screen and (max-width: 1200px) {
    .text {
        font-size: 15px;
        padding: 16px 8px;
    }

}


@media only screen and (max-width: 800px) {

    .container {
        left: 15%;
        width: 80%;

    }

    .hero-section {
        /* position: fixed; */
        /* bottom: 0; */
        position: absolute;
        top: 490px;
        left: 50%;
        width: 80vw;
        height: 50vh;
        transform: translate(-50%, -50%);
        /* text-align: center; */
    }    

    .row .container:focus,
    .row .container:hover {
        transform: none;
        z-index: 1;
    }

    .text {
        font-size: 12px;
        padding: 32px 16px;
    }

}

@media only screen and (max-width: 600px) {
    .container {
        left: 25%;
        width: 100%;
    }

    .hero-section {
        position: absolute;
        top: 490px;
        left: 50%;
        width: 100vw;
        height: 50vh;
        transform: translate(-50%, -50%);
        /* text-align: center; */
    }    
    .text {
        font-size: 9px;
        padding: 8px 8px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@200;300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
}

a {
    text-decoration: inherit;
    color: inherit;
}

.quiz-bg {
    background-color: rgb(10, 228, 230);
}

.quiz-app {
    min-height: 98.5vh;
    background-color: rgb(10, 228, 230);
    margin: 5px;
    padding: 5px;
}

/*Home.css*/
.quiz-content {
    background-color: rgb(10, 228, 230);
    display: flex;
    justify-content: space-around;
}

.quiz-settings {
    background-color: rgb(10, 228, 230);
    color: white;
    text-shadow: 1px 1px 10px black, 0 0 25px blue, 0 0 5px darkblue;
    width: 45%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}

.banner {
    width: 600px;
    align-self: center;
    padding: 8px;
}
/*main container for settings */
.quiz-settings_select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    flex: 0.8;
    justify-content: space-evenly;
}

.quiz-name-selector {
    color: white;
    text-shadow: none;
    background-color:rgb(153, 153, 255)
}

.quiz-category-selector {
    color: white;
    display:flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-shadow: none;
    background-color:rgb(109, 109, 204)
}

.quiz-difficulty-selector {
    color: white;
    text-shadow: none;
    background-color:rgb(82, 82, 175)
}

/*Quiz.css*/
.quiz {
    height: 1000px;
    background-color: rgb(10, 228, 230);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;

}

.subtitle {
    background-color: darkblue;
    color: white;
    font-size: 25px;
    border: 1px solid black;
    box-shadow: 4px 4px 2px black;
    padding: 5px 10px;
}

.quizInfo {
    background-color: rgb(10, 228, 230);
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin: 10px;
}

/*result.css*/
.result {
    height: 100vh;
    background-color: rgb(10, 228, 230);
    color: white;
    text-shadow: 1px 1px 10px black, 0 0 25px blue, 0 0 5px darkblue;
    font-size: 100px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}


/*Question.css*/
.question {
    background-color: rgb(0, 109, 110);
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.singleQuestion {
    background-color: rgb(10, 228, 230);
    color: black;
    width: 95%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 5px solid white;
    padding: 20px;
    margin-top: 10px;
}

.options {
    background-color: rgb(0, 109, 110);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
}

.singleOption {
    background-color: white;
    color: rgb(31, 0, 63);
    font-size: 15px;
    font-weight: bold;
    width: 46%;
    height: 50px;
    padding: 15px 20px;
    margin: 10px;
    box-shadow: 0 0 2px black;
}

.select {
    background-color: rgb(0, 250, 42);
    color: white;
    text-shadow: 1px 1px 10px black, 0 0 25px blue, 0 0 5px darkblue;
    box-shadow: 0 0 1px black;
}

.wrong {
    background-color: rgb(233, 0, 0);
    color: white;
    text-shadow: 1px 1px 10px black, 0 0 25px blue, 0 0 5px darkblue;
    box-shadow: 0 0 1px black;
}

.controls {
    font-size: 30px;
    font-weight: bold;
    background-color: rgb(10, 228, 230);
    display: flex;
    width: 100%;
    justify-content: space-around;
}

/*Header.css*/
.quiz-title {
    text-transform: uppercase;
    color: white;
    text-shadow: 1px 1px 10px black, 0 0 25px blue, 0 0 5px darkblue;
    font-size: 8vw;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
}

.quiz-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quiz-divider {
    height: 10px;
    margin: 10px;
    width: 100vw;
    background-color: rgb(0, 102, 255);
}

@media (max-width: 1200px) {
    .quiz-title {
        font-size: 9vw;
    }
    .banner {
        width: 60%;
        padding: 0;
        padding-top: 20px;
    }
}

@media (max-width: 800px) {
    .quiz-title {
        font-size: 10vw;
    }
    .quiz-content {
        flex-direction: column-reverse;
        align-items: center;
    }
    .banner {
        width: 65%;
        padding: 0;
        padding-top: 20px;
    }
    .quiz-settings {
        width: 100%;
    }
    
}

@media (max-width: 614px) {
    .quiz-title {
        font-size: 11vw;
    }
    .singleOption {
        width: 100%;
    }
}